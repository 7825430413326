.section {
  color: black;
  width: 100%;
  padding: var(--page-spacing);
  background: var(--yellow-blue-light);
}

.content {
  max-width: var(--page-width);
  width: 100%;
  margin: 0 auto;
}

.intro {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 42px;
}

.introContent {
  width: fit-content;
  max-width: 640px;
}

.introText {
  margin-top: 32px;
  max-width: 580px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}

.science {
  flex-shrink: 0;
}

.heading {
  color: var(--color-green);
  font-size: 24px;
  margin-bottom: 8px;
}

.benefits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  padding: 32px 0;
}

.benefit {
  display: flex;
  align-items: stretch;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.61);
  padding: 24px;
  border-radius: var(--rounded);
  box-shadow: var(--box-shadow);
}

.benefitText {
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.benefitIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  /* background-color: whitesmoke; */
}

.cta {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 42px;
  align-items: center;
  padding: 42px 0 0;
}

.motivTitle {
  color: #2c3345;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
}

.motivSubtitle {
  margin-top: 8px;
  color: #39b7a0;
  font-size: 48px;
  font-weight: 900;
  line-height: 48px;
}

@media (max-width: 640px) {
  .cta {
    grid-template-columns: 1fr;
    justify-items: stretch;
    padding: 16px 0 16px;
  }
  .benefits {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .benefit {
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 24px 16px;
    border-radius: var(--rounded);
  }
  .heading {
    font-size: 20px;
  }
  .intro {
    grid-template-columns: 1fr;
  }
}
