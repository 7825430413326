.section {
  padding: var(--page-spacing);
  background: var(--pale-blue-light);
  color: var(--bodyDark);
}

.sectionTitle {
  color: var(--color-blue);
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: 900;
  line-height: 48px;
}

.content {
  max-width: var(--page-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
}

.cert {
  display: flex;
  background-color: rgba(255, 255, 255, 0.61);
  padding: 24px;
  border-radius: var(--rounded);
  gap: 32px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  flex-basis: 0;
  flex-grow: 2;
  align-items: center;
}

.doctorText {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

.text {
  width: fit-content;
  color: var(--bodyDark);
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}

.about {
  flex-basis: 0;
  flex-grow: 3;
  padding: 0 32px;
  text-align: center;
  max-width: 960px;
}

.highlight {
  color: var(--color-blue);
}

.stats {
  max-width: var(--page-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 48px;
  text-align: center;
  padding: 24px 32px 0;
}

.statCounter {
  font-size: 54px;
  font-weight: 900;
  margin: 32px 0 24px;
  color: #678b9b;
}

.statText {
  font-size: 18px;
}

@media (max-width: 640px) {
  .content {
    flex-direction: column;
    gap: 32px;
  }
  .doctor {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .about {
    padding: 0px;
  }
  .stats {
    flex-direction: column;
    gap: 8px;
    padding: 0 32px;
  }
}
