.container {
  padding: 0;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: fit-content;
    margin-bottom: 32px;

    @media screen and (max-width: 888px) {
        flex-direction: column;
          /* max-width: min-content; */
    }
}