.footer {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  background-color: rgb(11, 11, 11);
  box-shadow: rgba(33, 35, 38, 0.1) 0px -10px 10px -10px;
  text-align: left;
  padding: 24px 0 0;
  margin-top: auto;
}

.footerInner {
  max-width: 1232px;
  display: flex;
  color: #858585;
  justify-content: space-between;
  margin: 0 auto;
  gap: 48px;
  padding: 0 24px;
}

.column {
  white-space: nowrap;
  width: fit-content;
}
.columnHeader {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 16px;
  color: #e0e0e0;
}

.logoColumn {
  flex: 1 100%;
  align-items: center;
  display: flex;
}

.column p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

.link {
  font-size: 14px;
  line-height: 24px;
  display: block;
}

@media (max-width: 840px) {
  .footerInner {
    flex-wrap: wrap;
    justify-content: center;
  }
  .logoColumn {
    align-items: stretch;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
}

.copyright {
  margin: 0 auto;
  font-size: 10px;
  color: #858585;
  margin-top: 24px;
  padding: 4px 24px 4px;
  border-top: 1px solid #333;
  text-align: left;
}

@media (max-width: 480px) {
  .footerInner {
    flex-direction: column;
    justify-content: center;
    padding: 16px 16px 0;
  }
  .logo {
    width: 170px;
    height: auto;
  }

  .copyright {
    margin: 0 auto;
    font-size: 10px;
    color: #858585;
    margin-top: 16px;
    padding: 4px 16px 4px;
    border-top: 1px solid #333;
    text-align: left;
  }
}

.seal {
  width: 112px;
  min-height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}