.rt-TextFieldInput:where(
    [type="date"],
    [type="datetime-local"],
    [type="time"],
    [type="week"],
    [type="month"]
  ) {
  display: block !important;
}

.radix-themes {
  --default-font-family: var(--bodyFont);
}

.radix-themes {
  --cursor-button: pointer;
  --cursor-checkbox: pointer;
  --cursor-disabled: not-allowed;
  --cursor-link: pointer;
  --cursor-menu-item: pointer;
  --cursor-radio: pointer;
  --cursor-slider-thumb: grab;
  --cursor-slider-thumb-active: grabbing;
  --cursor-switch: pointer;
  --removed-body-scroll-bar-size: 0px;
}

body[data-scroll-locked] {
  overflow-x: unset !important;
  max-width: unset !important;
}
