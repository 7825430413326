.section {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 640px;
  background: #1c1d1e;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    235deg,
    rgba(84, 166, 186, 1) 0%,
    rgba(92, 154, 175, 1) 35%,
    rgba(25, 32, 41, 1) 100%
  );
}

.bg {
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* background: linear-gradient(180deg, rgba(32, 32, 32, 1) 0%, rgba(32, 32, 32, 0.7427564775910365) 28%, rgba(32, 32, 32, 0) 40%); */
}

.gd {
  display: none;
}

.bgImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right top;
}

.highlight {
  color: var(--color-teal);
}

.text {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 100%;
  min-height: 400px;

  max-width: 760px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 96px;
  gap: 32px;
}

.textBg {
  /* z-index: 3; */
  z-index: -1;
  content: "";
  position: absolute;
  height: 162%;
  width: 162%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: #1a3a45;
  opacity: 82%;
  filter: blur(240px);

  /* background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.58) 11%,
    rgba(0, 0, 0, 0.35) 43%,
    rgba(0, 0, 0, 0) 100%
  ); */
}

.pagehead {
  font: var(--h1);
  display: block;
  margin-bottom: var(--spacing-l);
}

.subheading {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
  display: block;
}

.logo {
  position: absolute;
  top: 128px;
  left: 96px;
  width: 240px;
  height: auto;
  z-index: 10;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(-100%);
  }
  40% {
    transform: translateY(calc(-100% - 24px));
  }
  60% {
    transform: translateY(calc(-100% - 15px));
  }
}

.readMore {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 20;
  mix-blend-mode: luminosity;
  animation: bounce 2s infinite;
}

@media (max-width: 1366px), (max-height: 720px) {
  .pagehead {
    font: 900 38px/40px var(--bodyFont);
    margin-bottom: var(--spacing-m);
  }

  .logo {
    top: 64px;
  }
}

@media (max-width: 640px) {
  .readMore {
    display: none;
  }
  .gd {
    display: block;
    z-index: 2;
    position: absolute;
    height: 200px;
    width: 150%;
    top: -100px;
    left: -50%;
    background: #1a3a45;
    opacity: 82%;
    filter: blur(40px);
    mix-blend-mode: multiply;
  }
  .section {
    height: min-content;
  }
  .bg {
    position: relative;
    height: 46vh;
    min-height: 320px;
    width: 100vw;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.58) 11%,
      rgba(0, 0, 0, 0.35) 43%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .bgImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top right;
  }
  .text {
    padding: 32px 32px 48px;
    gap: 8px;
    height: fit-content;
    background: whitesmoke;
    color: var(--bodyDark);
    align-items: stretch;
  }
  .textBg {
    display: none;
  }
  .logo {
    top: 16px;
    left: 24px;
    width: 170px;
  }
  .highlight {
    color: var(--color-green);
  }
}

@media (min-aspect-ratio: 20/9) {
  .bgImage {
    object-fit: contain;
    position: absolute;
    inset: 0;
  }

  .section {
    background: #54a6ba;
    min-height: 640px;
  }

  .text {
    background: linear-gradient(260deg, #54a6ba 7%, #5c9aaf 35%, #192029 100%);
  }
}
