/* DotLoader.module.css */

@keyframes dotKeyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

.loadingDots {
  text-align: center;
  width: 100%;
  min-width: 154px;
  height: 32px;
  justify-self: center;

}

.dot {
  animation: dotKeyframes 1.5s infinite ease-in-out;
  border-radius: 10px;
  display: inline-block;
  height: 9px;
  width: 9px;
  margin: 0 2px;
}

.dotLight {
  background-color: #fff;
  mix-blend-mode: soft-light;
}

.dotDark {
  background-color: var(--color-grey);
  mix-blend-mode: plus-darker;
}

.dot:nth-of-type(2) {
  animation-delay: 0.5s;
}

.dot:nth-of-type(3) {
  animation-delay: 1s;
}
