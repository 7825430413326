.section {
  color: var(--bodyDark);
  background-color: white;
  width: 100%;
}

.title {
  color: var(--color-green);
}

.content {
  max-width: var(--content-width);
  padding: var(--page-spacing);
  margin: 0 auto;
}

.questions {
  padding: var(--spacing-l) 0;
}

.cta {
  padding: var(--spacing-l);
  display: flex;
  justify-content: center;
}
