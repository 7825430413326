.button {
  font-family: unset;

  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--bodyDark);
  text-align: center;
  text-shadow: none;
  cursor: pointer;
  border: 0;
  padding: 20px 40px;
  flex-grow: 0;
  background-color: transparent;
  transition: background-color 0.3s;
  background-blend-mode: color-dodge;
  background-image: linear-gradient(
    36deg,
    rgba(113, 197, 227, 1) 11%,
    rgba(176, 255, 248, 1) 64%
  );
  border-radius: 35px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  @media (max-width: 640px) {
    width: 100%;
  }
}

.button.fw {
  width: 100%;
}

.button a {
  display: block;
}

.button:hover {
  background-color: var(--orange-light);
}
