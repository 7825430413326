.orderForm {
  width: 100%;
  background: linear-gradient(
    235deg,
    rgba(84, 166, 186, 1) 0%,
    rgba(92, 154, 175, 1) 35%,
    #323b48 100%
  );
  background: linear-gradient(
    240deg,
    rgba(194, 223, 234, 1) 0%,
    rgb(47, 106, 119) 42%,
    rgb(32, 46, 51) 90%
  );
  padding: var(--page-spacing);
}

.orderForm em {
  color: var(--color-yellow);
}

.content {
  max-width: var(--page-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.orderDetails {
  width: 540px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.heading {
  color: var(--color-teal);
}

.subheading {
  font-weight: 400;
 line-height: normal;
 letter-spacing: 1px;
}
.currency {
  font-size: 16px;
}
/* .only {
  position: relative;
} */

/* .priceIcon {
  height: 22px;
  margin-right: 6px;
  position: absolute;
  left: -46px;
  top: 50%;
  margin-top: -9px;
} */

.item {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  position: relative;
}

.item::after {
  content: '';
  position: absolute;
  inset: 0;
  top: 100%;
  height: 12px;
  border-bottom: 1px dashed #fff;
  mix-blend-mode: soft-light;
}

.consultationFee {
  font-style: italic;
  font-weight: 600;
}
.consultationFeeAmount::after {
  content: '';
  position: absolute;
  top: 48%;
  left: -1px;
  right: -3px;
  background-color: white;
  height: 2px ;
  border-radius: 2px;
  opacity: 1;
}

.consultationFeeAmount {
  color: #A0B8C5;

  position: relative;

}
.consultationFeeIcon {
  margin-left: 4px;
  color: var(--color-yellow)
}


.amount {
  font-size: 38px;
}

.total .currency {
  font-size: 22px;
}

.summary {
  background-color: rgba(40, 51, 50, 0.38);
  border-radius: 6px;
  box-shadow: 0 0 0 16pt rgba(40, 51, 50, 0.38);
  margin: 16px 0;
  font-style: italic;
  position: relative;
}

.total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  line-height: 30px;
  color: var(--color-yellow);
  font-size: 38px;
  margin-bottom: var(--spacing-l);
  font-style: normal;
}

.orderDisclaimer {
  font-weight: 300;
  line-height: normal;
  font-size: 14px;
  text-align: justify;
  max-width: 540px;
}

.side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 540px;
  max-width: 100%;
  padding: 8px 20px;
}

.sideHeadline {
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 16px;
}

.sideHeadlineBox {
  width: max-content;
}

.underline {
  transform: translateY(-24px) scale(87%);
}

/* ----------------------------------------------*/

@media (max-width: 640px) {
  .orderDetails {
    gap: 16px;
  }

  .item {
    font-size: 18px;
  }
}

@media (max-width: 960px) {
  .orderForm {
    background: linear-gradient(
      221deg,
      #273231 24.45%,
      #305968 51.33%,
      #568799 72.8%,
      #6baea3 91.86%
    );
  }

  .content {
    flex-direction: column;
  }

  .orderDetails {
    width: 640px;
    gap: 24px;
  }

  .item {
    font-size: 18px;
  }

  .summary {
    box-shadow: 0 0 0 10pt rgba(40, 51, 50, 0.38);
  }
}

@media (max-width: 1280px) {
  .sideHeadline {
    font-size: 48px;
  }
}

@media (max-width: 960px) {
  .side {
    display: none;
  }
}
