.section {
  background: linear-gradient(220deg, #e0e0e0 32.07%, #cfe4ec 77.65%);
  width: 100%;

  padding: 8px 0;
}

.steps {
  max-width: var(--page-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: baseline;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 8px;
  }
}

.step {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.step:not(:last-of-type)::after {
  content: "";
  width: 130px;
  height: 30px;
  background-image: url("/arrow.svg");
  position: absolute;
  top: 50px;
  right: -64px;
}

.text {
  max-width: 240px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 22px;
  color: var(--bodyDark);
}

.icon {
  padding: 8px;
}

@media (max-width: 640px) {
  .icon {
    width: 64px;
    height: 64px;
    padding: 0;
  }
  .steps {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .step {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .step:not(:last-of-type)::after {
    display: none;
  }

  .text {
    text-align: left;
    padding: 16px 8px 0 20px;
  }
}
