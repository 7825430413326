.embla {
  max-width: 100dvw;
  overflow: hidden;
}

.emblaViewport {
  overflow: hidden;
}

.emblaContainer {
  display: flex;
  touch-action: pan-y pinch-zoom;
  align-items: center;
}

.emblaSlide {
  flex: 0 0 80%;
  transform: translate3d(0, 0, 0);
  min-width: 0;
  max-width: 100dvw;
  margin: 24px 12px 24px 12px;

  @media (min-width: 768px) {
    flex: 0 0 620px;
  }
}
