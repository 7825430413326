.details {
  border-bottom: 1px solid #d9dddb;
  font-size: 18px;
  padding-right: 48px;
  /* font-weight: 500; */
  /* backdrop-filter: blur(10px); */
}

.summary {
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 16px 4px;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  text-align: left;
}

.summary h3 {
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 640px) {
    font-size: 18px;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin: 6px;
  flex-shrink: 0;

  @media (max-width: 640px) {
    width: 24px;
    height: 24px;
  }

  background-color: var(--color-grey);
  clip-path: polygon(
    40% 40%,
    40% 0%,
    60% 0%,
    60% 40%,
    100% 40%,
    100% 60%,
    60% 60%,
    60% 100%,
    40% 100%,
    40% 60%,
    0% 60%,
    0% 40%
  );
}

.iconOpen {
  transform: rotate(136deg);
}

.content {
  display: none;
}

.contentOpen {
  display: block;
  padding: 2px 6px 16px;
}
